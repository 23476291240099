export const canSeeProjects = () => {
    if (localStorage.getItem('email') !== null && localStorage.getItem('email') &&
      ['seth', 'qa', 'anson', 'max', 'cesar', 'luca', 'arkady'].filter(x =>
      localStorage.getItem('email').includes(x)).length > 0) {
        return true
    }
    return false;
}

const SystemDesignType = {
  ENGINEERED: 'Engineered Design',
  ENGINEERED_RECORD_NAME: 'Engineered_Design',
  SALES: 'Sales Design',
  SALES_RECORD_NAME: 'Sales_Design',
  NONE: 'None',
  ERROR: 'Error'
}

const FetchRecordTypes = () => {
  return fetch('/api/system-designs/types')
    .then(response => response.json())
    .then(data => {
      localStorage.setItem('pw-recordTypes', JSON.stringify(data));
      return data;
    })
    .catch(error => {
      console.error('Error:', error);
    });
}

export const getSystemDesingType = async ({RecordTypeId, System_Design_Type__c}) => {
  try {
    let systemDesignTypes = JSON.parse(localStorage.getItem('pw-recordTypes'));
    if (!systemDesignTypes) {
      systemDesignTypes = await FetchRecordTypes();
    }
    if (systemDesignTypes[RecordTypeId] === SystemDesignType.ENGINEERED_RECORD_NAME) {
      return SystemDesignType.ENGINEERED;
    }
    if (systemDesignTypes[RecordTypeId] === SystemDesignType.SALES_RECORD_NAME) {
      return SystemDesignType.SALES;
    }
    if (System_Design_Type__c === SystemDesignType.ENGINEERED) {
      return SystemDesignType.ENGINEERED;
    }
    if (System_Design_Type__c === SystemDesignType.SALES) {
      return SystemDesignType.SALES;
    }
  } catch (error) {
    return SystemDesignType.ERROR;
  }
}

export const isSalesDesign = async (systemDesignData) => {
  const system_design = await getSystemDesingType(systemDesignData);
  return system_design === SystemDesignType.SALES;
}

export const isEngineeredDesign = async (systemDesignData) => {
  const system_design = await getSystemDesingType(systemDesignData);
  return system_design === SystemDesignType.ENGINEERED;
}