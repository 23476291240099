/* eslint-disable @typescript-eslint/no-explicit-any */
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import callApiJson from './callApi';

const uniqueStageNames = [
  'Quoting',
  'Underwriting',
  'Offtaker Negotiation',
  'Engineering',
  'Closed Won',
  'Closed Lost',
  //
  'Letter of Intent (LOI)',
  'E&C Diligence',
  'Financial Review',
  'Change Order'
];



const useGetOpportunities = ({
    perPage,
    pageNumber,
    searchTerm,
    stageFilters,
    sort,
    statusFilter
}: {
    perPage: number,
    pageNumber: number,
    searchTerm?: string,
    stageFilters?: string[],
    sort?: string,
    statusFilter?: string
}) => useQuery({
    queryKey: ["opportunities", perPage, pageNumber, searchTerm, stageFilters, sort, statusFilter],
    queryFn: async () => {
        try {
          const url = getSearchURL(perPage, pageNumber, searchTerm, stageFilters, sort, statusFilter);
          const data = await callApiJson(url);

          // review this, i think this is not needed
          data?.opportunities?.map((x: any) => {
            x.applicationId = x.Id;
            x.businessName = x.Name === null ? 'Business Name Doesnt Exist' : x.Name;
            x.status = x.IsWon === true ? 'Won' :
              (x.StageName.toLowerCase().includes('lost') ? 'Lost' : 'Active');
            x.projectId = x.Project_ID__c;
            x.projectState = x.project_state__c;
          });

          // Remove Assemblage opportunities
          data.opportunities = data?.opportunities?.filter((x: any) => x.Name.toLowerCase().includes('assemblage') === false);

          const renderStageNames = uniqueStageNames.map(x => { return { 'value': x, 'label': x } });

          return {
            total: data?.count || 0,
            opportunities: data?.opportunities,
            renderStageNames
          };
        } catch (error) {
          console.log(error);
          toast.warning('Failed to retrieve opportunities, retry', {
            position: toast.POSITION.TOP_RIGHT
          });
          throw new Error('Failed to retrieve opportunities');
        }
    },
    enabled: !!perPage && !!pageNumber,
    staleTime: Infinity,
    placeholderData: keepPreviousData,
  });

export default useGetOpportunities;

const getSearchURL = (
  perPage: number,
  pageNumber: number,
  searchTerm?: string,
  stageFilters?: string[],
  sort?: string,
  statusFilter?: string,
) => {
  const basePath = "/api/opportunities";
  const queryParams: {
    perPage: number,
    pageNumber: number,
    searchTerm?: string,
    stageFilters?: string[],
    sort?: string,
    statusFilter?: string
  } = {
    perPage,
    pageNumber
  }

  if (searchTerm) {
    queryParams.searchTerm = searchTerm
  }

  if (stageFilters) {
    queryParams.stageFilters = stageFilters
  }

  if (sort) {
    queryParams.sort = sort
  }

  if (statusFilter) {
    queryParams.statusFilter = statusFilter
  } else {
    queryParams.statusFilter = 'Active'
  }

  const queryString = new URLSearchParams(Object.entries(queryParams).reduce((acc, [key, value]) => {
      if (value !== undefined) {
          acc[key] = String(value);
      }
      return acc;
  }, {} as Record<string, string>)).toString();

  return `${basePath}?${queryString}`;
}