import { useQuery } from '@tanstack/react-query';
import { skipNestedAttributes } from '../constants';
import { toast } from 'react-toastify';
import { InputFormPayload, initializeFormPayload } from '../components/CalculatorForm/types';
import { SystemDesign } from './useOptimizeInputs';
import callApiJson from './callApi';

const useCalculatorData = (
  selectedSystemDesign: string, 
  selectedQuoteId: string,
  selectedOpportunityId: string
  ) => useQuery({
  queryKey: ['calculatorData', selectedSystemDesign, selectedQuoteId, selectedOpportunityId],
  queryFn: async () => {
    try {
      let sdData: SystemDesign | undefined = undefined ;
      if (selectedSystemDesign) {
        const data = await callApiJson('/api/system-designs?id=' + selectedSystemDesign);
        // if sdData has error, return sdData type systemDesign with "" values
        sdData = skipNestedAttributes(data);
      }
      if (sdData?.error || sdData === undefined) {
        sdData = {
          degradation_pcnt: "",
          pv_fcst_prod_yr1_jan_1: "",
          pv_fcst_prod_yr1_feb_2: "",
          pv_fcst_prod_yr1_mar_3: "",
          pv_fcst_prod_yr1_apr_4: "",
          pv_fcst_prod_yr1_may_5: "",
          pv_fcst_prod_yr1_jun_6: "",
          pv_fcst_prod_yr1_jul_7: "",
          pv_fcst_prod_yr1_aug_8: "",
          pv_fcst_prod_yr1_sep_9: "",
          pv_fcst_prod_yr1_oct_10: "",
          pv_fcst_prod_yr1_nov_11: "",
          pv_fcst_prod_yr1_dec_12: "",
          sr_equip_cost_nom: "",
          installer_cost_nom: "",
          sr_dev_cost_nom: "",
          additional_epc_cost_nom: "",
          third_party_dev_cost_nom: "",
          additional_epc_cost_per_watt: "",
          installer_cost_per_watt: "",
          sr_dev_cost_per_watt: "",
          sr_equip_cost_per_watt: "",
          third_party_dev_cost_per_watt: "",
        }
      }

      let data = await callApiJson('/api/opportunities?id=' + selectedOpportunityId);
      const opData = skipNestedAttributes(data);

      let quotesData: InputFormPayload | undefined = undefined;
      if (selectedQuoteId) {
        data = await callApiJson('/api/quotes?id=' + selectedQuoteId);
        quotesData = skipNestedAttributes(data) as InputFormPayload;
      }

      const previousFormData = initializeFormPayload(quotesData, sdData, opData);

      return {
        systemDesignData: sdData,
        opportunityData: opData,
        selectedQuoteData: quotesData,
        previousFormData
      }
    } catch (error) {
      console.log(error);
      toast.warning('Failed to retrieve data on outputs, retry' + error.toString(), {
        position: toast.POSITION.TOP_RIGHT
      });
      throw new Error("Failed to retrieve data on outputs");
    }
  },
  enabled: !!selectedOpportunityId,
});

export default useCalculatorData;
